/* eslint-disable react/forbid-prop-types */
import React from 'react';
import cx from 'classnames';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import PropTypes from 'prop-types';
import lowerCase from 'lodash/lowerCase';
import useTranslation from 'next-translate/useTranslation';
import { createGlobalStyle } from 'styled-components';
import accents from '../../utils/accents';
import { HeroBackgroundImage, CtaButtons, ScrollText } from './SharedElements';
import HeroModulePropTypes from './SharedElements/Module/HeroPropTypes';
import Image, { ImagePropTypes } from '../Image';
import TradeMark from '../../generics/TradeMark';
import ErrorBoundary from '../ErrorBoundary';
import heroStyles from './Hero.styles';
import sidekickInit, { sidekick } from '../../utils/sidekick/init';

export const HeroPropTypes = {
  ...HeroModulePropTypes,
  contentOffset: PropTypes.oneOf(['Left', 'Right', 'None']),
  logo: PropTypes.shape(ImagePropTypes),
  image: PropTypes.shape(ImagePropTypes).isRequired,
  imageTablet: PropTypes.shape(ImagePropTypes),
  imageMobile: PropTypes.shape(ImagePropTypes),
  videoDesktop: PropTypes.object,
  videoMobile: PropTypes.object,
  anchor: PropTypes.string,
  backgroundColor: PropTypes.string,
  hasSubnav: PropTypes.bool
};
const GlobalStyle = createGlobalStyle`
  .heroWrapper {
    img,
    a,
    h1,
    h2,
    p,
    button,
    video {
      transition: .3s;
      transition-delay: .2s;
    }
    > div {
      transition: .3s;
      transition-delay: .2s;
    }
    .Hero-content {
      max-width: 600px;
    }
  }
 .async-hide {
   .heroWrapper {
     img,
      a,
      h1,
      h2,
      p,
      button,
      video {
        transition: 0s;
        transition-delay: 0s;
        opacity: 0 !important;
      }

      > div {
        transition: 0s;
        transition-delay: 0s;
        background-color: transparent;
      }
    }
  }
`;
function Hero({
  _id,
  _contentTypeId,
  textJustification,
  textTheme,
  height = 'Full',
  contentOffset,
  logo,
  title,
  subtitle,
  body,
  image,
  imageTablet,
  imageMobile,
  videoDesktop,
  videoMobile,
  scrollText,
  scrollTextTheme,
  button,
  anchor,
  backgroundColor,
  hasSubnav
}) {
  const { lang } = useTranslation();
  const props = {
    textJustification,
    contentOffset: lowerCase(contentOffset),
    height: lowerCase(height),
    textTheme,
    lang,
    accents: accents(title),
    noMarginBottom: hasSubnav
  };
  const css = heroStyles(props);
  const { sidekicker } = sidekickInit({ _id, _contentTypeId });
  const gridCols = (justify, offset) => {
    switch (true) {
      case justify === 'center' && (!offset || offset === 'None'):
        return 12;
      case justify === 'left' && offset !== 'Right':
        return 8;
      case offset === 'Left':
      case offset === 'Right':
      default:
        return 6;
    }
  };

  return (
    <>
      <GlobalStyle />
      <ErrorBoundary>
        <Container
          disableGutters
          maxWidth={false}
          className="heroWrapper"
          id={anchor?.toLowerCase()?.trim() !== '' ? anchor?.toLowerCase() : _id}
          {...sidekick(_id, null, null, 'Hero')}
        >
          <HeroBackgroundImage
            data-test-id="Hero-HeroBackgroundImage"
            height={lowerCase(height)}
            image={image}
            imageTablet={imageTablet}
            imageMobile={imageMobile}
            videoDesktop={videoDesktop}
            videoMobile={videoMobile}
            backgroundColor={backgroundColor}
          />
          <Container data-testid="Hero" maxWidth="xl" className={css.hero}>
            <Grid container>
              <Hidden mdDown implementation="css">
                <Grid item md={contentOffset === 'Right' && 5} />
              </Hidden>
              <Grid
                item
                data-testid="Hero-content"
                xs={12}
                lg={gridCols(textJustification, contentOffset)}
                className={cx(css.content, 'Hero-content', {
                  [css.contentCenter]: textJustification === 'center'
                })}
              >
                {logo && (
                  <Box className={css.logo}>
                    <Image
                      testId="Hero-logo"
                      image={logo}
                      priority
                      {...sidekicker('Logo')}
                      columns={4}
                    />
                  </Box>
                )}
                {title && (
                  <Typography
                    variant="h1"
                    data-testid="Hero-title"
                    className={`h1 ${css.title}`}
                    {...sidekicker('Title')}
                  >
                    <TradeMark>{title.split('\\n').join('\n')}</TradeMark>
                  </Typography>
                )}
                {subtitle && (
                  <Typography
                    variant="h2"
                    data-testid="Hero-subtitle"
                    className={`h2 ${css.subtitle}`}
                    {...sidekicker('Subtitle')}
                  >
                    <TradeMark>{subtitle}</TradeMark>
                  </Typography>
                )}
                {body && (
                  <Typography variant="body1" data-testid="Hero-body" {...sidekicker('Body')}>
                    <TradeMark bodyContent>{body}</TradeMark>
                  </Typography>
                )}
                <CtaButtons
                  button={button}
                  contentOffset={contentOffset}
                  textJustification={textJustification}
                />
              </Grid>
            </Grid>
            {scrollText && <ScrollText scrollText={scrollText} scrollTextTheme={scrollTextTheme} />}
          </Container>
        </Container>
      </ErrorBoundary>
    </>
  );
}

Hero.propTypes = HeroPropTypes;
Hero.defaultProps = {
  logo: null,
  contentOffset: null,
  imageTablet: null,
  imageMobile: null,
  videoDesktop: null,
  videoMobile: null,
  anchor: '',
  backgroundColor: 'transparent',
  hasSubnav: false
};

export default React.memo(Hero);
