import { makeStyles } from '@material-ui/core/styles';

const heroStyles = makeStyles((theme) => ({
  hero: ({ textJustification, contentOffset, height, textTheme }) => {
    const leftAlignedCss = textJustification === 'left' && {
      'textAlign': 'left',
      '& h1': {
        marginLeft: -3.375
      }
    };

    const heroBase = {
      'position': 'relative',
      'display': 'flex',
      'alignItems': 'flex-start',

      // '@media (min-width: 768px)': {
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        maxWidth: '850px',
        paddingRight: '98.5px',
        paddingLeft: '98.5px'
      },

      // '@media (min-width: 1024px)': {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '1080px',
        paddingRight: '50px',
        paddingLeft: '50px'
      },

      // '@media (min-width: 1440px)': {
      [theme.breakpoints.up('xl')]: {
        maxWidth: '1440px'
      },

      // '@media (max-width: 767.95px)': {
      [theme.breakpoints.down('sm')]: {
        paddingTop: '8rem'
      },

      // iPhone 6/7/8/8+ landscape
      '@media (max-height: 520px) and (max-width: 767.98px)': {
        paddingTop: 'calc(100vh - 50%)'
      },

      // iPhone X/XS landscape
      '@media (max-height: 400px) and (max-width: 813px)': {
        alignItems: 'flex-start !important',
        paddingTop: '8vh',

        ...(contentOffset &&
          contentOffset !== 'none' && {
            '& h1': {
              fontSize: '7.5rem !important',
              lineHeight: '6rem !important'
            }
          })
      },
      ...leftAlignedCss
    };

    let heightCss = '';
    switch (height) {
      case height === 'short':
        heightCss = {
          'minHeight': 'calc(35vh - 70px)',
          'paddingTop': '1.8rem',

          '@media (min-width: 768px)': {
            // [theme.breakpoints.up('md')]: {
            minHeight: 'calc(35vh - 90px)'
          }
        };
        break;
      case height === 'medium':
        heightCss = {
          'minHeight': 'calc(80vh - 70px)',

          '@media (min-width: 768px)': {
            // [theme.breakpoints.up('md')]: {
            minHeight: 'calc(80vh - 90px)'
          }
        };
        break;
      case height === 'full':
      default:
        heightCss = {
          'minHeight': 'calc(100vh - 70px)',
          'marginTop': 'calc(-100vh + 70px)',

          '@media (min-width: 768px)': {
            // [theme.breakpoints.up('md')]: {
            minHeight: 'calc(100vh - 90px)',
            marginTop: 'calc(-100vh + 90px)'
          }
        };
    }

    const textThemeCss =
      textTheme === 'light'
        ? {
            'color': 'white',

            '& h1, & h2, & h3, & p': {
              color: theme.palette.white
            }
          }
        : {
            'color': 'black',

            '& h1, & h2, & h3, & p': {
              color: theme.palette.black
            }
          };

    return {
      textAlign: textJustification,
      ...textThemeCss,
      ...heightCss,
      ...heroBase
    };
  },

  content: ({ contentOffset, accents }) => {
    return {
      'display': 'flex',
      'flexDirection': 'column',

      // '@media (max-width: 767.95px)': {
      [theme.breakpoints.down('sm')]: {
        // maxWidth: '100%',
        padding: 0
      },

      // '@media (max-width: 1023.95px)': {
      [theme.breakpoints.down('md')]: {
        'maxWidth': '100%',
        'flexBasis': '100%',

        '& h2': {
          paddingBottom: '1rem',
          fontSize: '1.9375rem',
          lineHeight: '1.813rem'
        }
      },

      '& p': {
        marginBottom: '1rem'
      },

      ...((!contentOffset || contentOffset === 'left' || contentOffset === 'none') && {
        '@media (min-width: 1024px)': {
          // [theme.breakpoints.up('lg')]: {
          paddingRight: theme.spacing(6)
        }
      }),

      ...(contentOffset === 'right' && {
        '@media (min-width: 1024px)': {
          // [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(6)
        }
      }),

      ...(!!contentOffset &&
        contentOffset !== 'none' && {
          // '@media (min-width: 1024px)': {
          [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
            flexBasis: '58.333%',
            maxWidth: '58.333%'
          },

          '& h1': {
            fontSize: '6.6rem',
            lineHeight: '5.2rem',

            ...(!!accents && {
              'lineHeight': '6.4rem',

              '& sup': {
                top: '0.9em'
              }
            }),

            // '@media (min-width: 768px)': {
            [theme.breakpoints.up('md')]: {
              fontSize: '9.125rem',
              lineHeight: '7.3125rem',

              ...(!!accents && {
                lineHeight: '8.8rem'
              })
            },

            // '@media (min-width: 1024px)': {
            [theme.breakpoints.up('lg')]: {
              fontSize: '9.8125rem',
              lineHeight: '8rem',

              ...(!!accents && {
                'lineHeight': '9.6rem',

                '& sup': {
                  top: '0.95em'
                }
              })
            },

            // '@media (min-width: 1440px)': {
            [theme.breakpoints.up('xl')]: {
              fontSize: '12rem',
              lineHeight: '9rem',

              ...(!!accents && {
                lineHeight: '11.6rem'
              })
            }
          },

          // Burger King in CA
          '& img[src*="Whopper"]': {
            width: '80%'
          },
          '& img[src*="Now_in_Canada"]': {
            width: '80%'
          }
        }),

      ...((!contentOffset || contentOffset === 'none') && {
        // '@media (min-width: 1440px)': {
        [theme.breakpoints.up('xl')]: {
          paddingRight: '50px',
          paddingLeft: '50px'
        },

        '& h1': {
          // '@media (min-width: 768px)': {
          [theme.breakpoints.up('lg')]: {
            fontSize: '7.813rem',
            lineHeight: '6.75rem',

            ...(!!accents && {
              lineHeight: '9.4rem'
            })
          },

          ...(!!accents && {
            lineHeight: '7.6rem'
          }),

          // '@media (min-width: 1440px)': {
          [theme.breakpoints.up('xl')]: {
            fontSize: '9.375rem',
            lineHeight: '7.5rem'
          }
        }
      })
    };
  },

  contentCenter: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    margin: '0 auto'
  },

  title: ({ lang }) => {
    const base = {
      marginBottom: '0.75rem',
      whiteSpace: 'pre-line',
      fontSize: '7.8125rem',
      textTransform: 'uppercase !important',

      // '@media (min-width: 1024px)': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '6rem',
        lineHeight: '5rem'
      },

      // '@media (min-width: 768px)': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.375rem',
        lineHeight: '3.75rem'
      }
    };

    switch (true) {
      case lang === 'cn':
        return {
          ...base,
          fontSize: '6rem !important',

          // '@media (max-width: 767.95px)': {
          [theme.breakpoints.down('sm')]: {
            fontSize: '2.1rem !important'
          },

          // '@media (min-width: 0px)': {
          [theme.breakpoints.down('xl')]: {
            fontSize: '3.5rem !important',
            lineHeight: '1.2 !important'
          }
        };
      case lang === 'hk':
        return {
          ...base,
          paddingTop: '0.4rem !important',
          fontWeight: '700 !important',
          lineHeight: '1 !important'
        };
      case lang === 'cn-en':
        return {
          ...base,
          fontSize: '7rem !important',
          lineHeight: '1 !important',

          // '@media (max-width: 767.95px)': {
          [theme.breakpoints.down('sm')]: {
            fontSize: '2.6rem !important'
          }
        };
      default:
        return base;
    }
  },

  logo: ({ textJustification }) => {
    return {
      'alignSelf': textJustification,
      'height': '3.75rem',
      'marginBottom': '1.5rem',

      '& img': {
        width: 'auto',
        maxWidth: '100%',
        height: '3.75rem'
      }
    };
  },

  subtitle: ({ accents }) => {
    return {
      ...(!!accents && {
        lineHeight: '2.5rem'
      }),

      '& sup': {
        top: '1.5em'
      }
    };
  }
}));

export default heroStyles;
